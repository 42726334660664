/*
 * last modified---
 * 	10-06-23 new
 *
 * purpose---
 * 	provide a Toast for use with expandable data that may not otherwise fit
 */

import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

/* dismissable Toast closed by default with toggle button to open
 * @param props.variant type of toast
 * @param props.buttonText prompt text for button
 * @param props.buttonTitle hover text for button
 * @param props.buttonIcon file to use for icon inside button
 * @param props.title any title to display for the toast
 * @param props.data any valid HTML to be displayed in the toast
 */
function DataToast(props) {
	const [displayData, setDisplayData] = useState(false);
	const toggleDisplayData = () => setDisplayData(!displayData);

	return (
	<>
		<Button
			onClick={toggleDisplayData}
			variant={props.variant}
			title={props.buttonTitle}
		>
			{props.buttonText}
			<Image src={props.buttonIcon} className="p-2" fluid rounded
				height={30} width={30} />
		</Button>
		<Toast show={displayData} onClose={toggleDisplayData} bg="secondary">
			<Toast.Header>
				<small>{props.title}</small>
			</Toast.Header>
			<Toast.Body>
				{props.data}
			</Toast.Body>
		</Toast>
	</>
	);
}

export default DataToast;
