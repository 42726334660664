/*
 * last modified---
 * 	08-22-23 new
 *
 * purpose---
 * 	provide notices for incorrect (non-deployed) networks and missing artifacts
 */

import React from 'react';

// notice we have wrong network
function NoticeWrongNetwork() {
    return (
        <p>
            ⚠️ Wallet is not connected to the same network to which Enshroud
			has been deployed.
        </p>
    );
}

// notice we're not deployed here
function NoticeNoArtifact() {
    return (
        <p>
            ⚠️ Cannot find <span className="code">EnshroudProtocol</span>
            contract artifact.
        </p>
    );
}

export default NoticeWrongNetwork;
export { NoticeNoArtifact };
