/*
 * last modified---
 * 	01-15-24 add stakingAuth state, handleStakingAuth()
 * 	05-18-23 new
 *
 * purpose---
 * 	provide admin UI to MVO stakings on chain
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Enshroud.css';
import { useState } from 'react';
import useEth from '../EthContext/useEth';
import NoticeWrongNetwork, { NoticeNoArtifact } from '../Notices.jsx';
import UpdateMVOStakings from './UpdateMVOStakings.jsx';


/* framework for holding state and displaying MVOConfig-related UI
 * @param props dummy variable (cue to React)
 */
function MVOConfig(props) {
	const { state } = useEth();
	const [mvoStakings, setMvoStakings] = useState([]);
	const [minStaking, setMinStaking] = useState(150000);
	const [stakingAuth, setStakingAuth] = useState(false);

	// method to add an array of stakings by replacing state
	function handleAddStaking(stakings) {
		setMvoStakings([...mvoStakings, ...stakings]);
	}

	// method to handle updating a staking
	function handleChangeStaking(updStaking) {
		setMvoStakings(mvoStakings.map(s => {
			if (s.mvoId === updStaking.mvoId) {
				return updStaking;
			} else {
				return s;
			}
		}));
	}

	// method to update minimum staking to fetched value
	function updateMinStaking(minStake) {
		if (minStake > 0) setMinStaking(minStake);
	}

	// method to set whether current user account is a valid admin
	function handleStakingAuth(isAuth) {
		setStakingAuth(isAuth);
	}

	// build output
	const mvoConfig =
		<>
			<div className="container">
				<UpdateMVOStakings stakings={mvoStakings}
					minStaking={minStaking}
					updMinStake={updateMinStaking}
					onNewStaking={handleAddStaking}
					onUpdateStaking={handleChangeStaking}
					isStakingAuth={stakingAuth}
					setStakingAuth={handleStakingAuth}
			/>
			</div>
		</>;

	return (
		<div id="MVOconf">
		{
			!state.artifacts.MVOStaking ? <NoticeNoArtifact /> :
			state.contracts == null ||
					!state.contracts["MVOStaking"] ? <NoticeWrongNetwork /> :
				mvoConfig
		}
		</div>
	);
}

export default MVOConfig;
