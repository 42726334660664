/*
 * last modified---
 * 	07-26-24 v0.2.0 Beta (add Crowdsale and stubs for staking pages)
 * 	04-30-24 v0.1.2 Beta
 * 	04-17-24 v0.1.1 Beta, add support forum link
 * 	03-26-24 v0.1.0 Beta
 * 	09-13-23 new
 *
 * purpose---
 * 	footer for dApp pages
 */

import React from 'react';
import Container from 'react-bootstrap/Container';

function EnshFooter() {
	return (
		<footer>
			<Container align="center" className="d-flex-inline">
				Version 0.2.0 Beta &middot; 26 July, 2024 &middot;
				Enshroud Developers &middot;&nbsp;
				<small>
					<a href="https://support.enshroud.info:7443/inverse/#converse/login" target="_blank" rel="noreferrer noopener">
						Support forum
					</a>
					<br/>
					<i>Social media links go here</i> (TBD)
				</small>
			</Container>
		</footer>
	);
}

export default EnshFooter;
