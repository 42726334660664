/*
 * last modified---
 * 	05-19-23 new
 *
 * purpose---
 * 	base page for generating dApp content
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Enshroud.css';
import Enshroud from './Enshroud.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  	<Enshroud />
  </React.StrictMode>
);
