/*
 * last modified---
 * 	07-26-24 new (stub)
 *
 * purpose---
 * 	provide stakings UI for user MVO staking management
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Enshroud.css';
import { useState } from 'react';
import useEth from '../EthContext/useEth';
import NoticeWrongNetwork, { NoticeNoArtifact } from '../Notices.jsx';


/* framework for holding state and displaying MVO staking-related UI
 * @param props dummy variable (cue to React)
 */
function MVOStaking(props) {
	const { state } = useEth();
	const [userTokens, setUserTokens] = useState(0);

	// method to set user balance of $ENSHROUD tokens
	function handleSetBalance(newBal) {
		if (newBal >= 0) {
			setUserTokens(newBal);
		}
	}

	// build output
	const mvoStaking =
		<>
			<h2 align="center">
				MVO Staking Management (for L2 node owners/operators)
			</h2>
			<br/>
			<div className="container">
				<h3>TBD</h3>
				<br/>
			</div>
		</>;

	// render output as appropriate
	return (
		<div id="MvoStakingConf">
		{
			!state.artifacts.EnshroudToken ? <NoticeNoArtifact /> :
			state.contracts == null
				|| !state.contracts["EnshroudToken"] ? <NoticeWrongNetwork /> :
				mvoStaking
		}
		</div>
	);
}

export default MVOStaking;
