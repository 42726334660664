/*
 * last modified---
 * 	08-07-23 new
 *
 * purpose---
 * 	provide a "loading" button for use with network access operations
 */

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

/* button with loading indicator which invokes passed function and blocks
 * double-clicks
 * @param props.variant type of button
 * @param props.netMethod function making network access
 * @param props.buttonText prompt text for button
 * @param props.buttonTitle title (mouseover) text for button
 * @param props.buttonIcon file to use for icon inside button
 * @param props.buttonStyle className for button (defaults to "" if not set)
 */
function LoadingButton(props) {
	const [isLoading, setLoading] = useState(false);
	const netFn = props.netMethod;
	const style = (props.buttonStyle === undefined ? "" : props.buttonStyle);

	useEffect(() => {
		function doRequest() {
			return new Promise((resolve, reject) => netFn(resolve, reject));
		}

		if (isLoading) {
			doRequest().then(() => {
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err.message);
			});
		}
	}, [isLoading, netFn]);
	const handleClick = () => setLoading(true);

	return (
		<Button
			variant={props.variant}
			title={props.buttonTitle}
			disabled={isLoading}
			onClick={!isLoading ? handleClick : null}
			className={style}
		>
			{isLoading ? 'Loading…' : props.buttonText}
				<Image src={props.buttonIcon} className="p-2" fluid rounded
					height="40" width="40"/>
		</Button>
	);
}

export default LoadingButton;
