/*
 * last modified---
 * 	07-16-24 add Staking menu
 *  12-29-23 add Greylisting to admin menu (greylistAdmin key)
 * 	12-05-23 add History link in top menu (to receipts.jsx page)
 * 	11-02-23 remove Connect Wallet link from offCanvas menu
 * 	09-14-23 add real (possibly temp) logo
 * 	07-19-23 remove Connect and network dropdown (now controlled from wallet)
 * 	07-18-23 set ChainConnection in caller (<Enshroud/>)
 * 	07-10-23 add enshMinting admin eventKey
 * 	05-31-23 add downloadMVOconfig()
 *
 * purpose---
 * 	supply menus for dApp
 */

import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useEth from './EthContext/useEth';

function EnshNavbar(props) {
	// enable use of our connection and accounts
	const { state: { accounts, chainConn } } = useEth();

	if (accounts == null || chainConn == null) {
		// NB: this is normal on first render before chainConn gets established
		//console.error("thread sync error, null vars in navbar");
		return;
	}

	// show account in shorthand
	const acct
		= accounts[0].substring(0, 7) + '...' + accounts[0].substring(37);

	// set our parent to know about the current ChainConnection state
	// (does nothing if no change occurred)
	props.updateChain(chainConn, accounts[0]);

	return (
		<Container align="center" valign="top">
			<Navbar expand="none" fixed="top" variant="dark" bg="dark">
				<Container fluid>
					<Nav className="d-flex justify-content-start">
						{ /* right side offCanvas menu, left side of screen */ }
						<Navbar.Toggle aria-controls={'offcanvasNavbar-expand'} title="Menu toggle"/>
						<Navbar.Offcanvas
						 id='offcanvasNavbar-expand'
						 aria-labelledby={'offcanvasNavbarLabel-expand'}
						 placement="end"
						 className="text-bg-dark"
						 tabIndex="-1"
						 scroll="true"
						>
							<Offcanvas.Header closeButton="true" closeVariant="white">
								<Offcanvas.Title id={'offcanvasNavbarLabel-expand'}>
									Full Navigation Menu
								</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav variant="tabs"
									activeKey={props.activePage}
									onSelect={(selectedKey) => props.onSelect(selectedKey)}
								>
									<Nav.Link eventKey="home">Home</Nav.Link>
									<Nav.Link eventKey="downloadWallet">Download Wallet</Nav.Link>
									<Nav.Link eventKey="depositAssets">Deposit Assets</Nav.Link>
									<Nav.Link eventKey="mintENFTs">Mint eNFTs</Nav.Link>
									<Nav.Link eventKey="spendENFTs">Spend eNFTs</Nav.Link>
									<Nav.Link eventKey="burnENFTs">Burn eNFTs</Nav.Link>
									<Nav.Link eventKey="assetConfig">Asset Config</Nav.Link>
									<Nav.Link eventKey="restoreWallet">Restore Wallet Backup</Nav.Link>
									<Nav.Link eventKey="receipts">Transaction History</Nav.Link>
									<Nav.Link eventKey="daoStaking">User Staking</Nav.Link>
									<Nav.Link eventKey="mvoStaking">MVO Management</Nav.Link>
									<Nav.Link eventKey="crowdSale">Crowdsale</Nav.Link>
								</Nav>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Nav>

					{ /* regular navbar */ }
					<Nav className="flex-row flex-grow-1 justify-content-start"
						defaultActiveKey="home"
						onSelect={(selectedKey) => props.onSelect(selectedKey)}
					>
						<Navbar.Brand href="#" title="Enshroud Logo" className="navbar-brand p-1 m-1"><img src="images/eye-slash.svg" width="45" height="45" className="d-inline-block align-top" alt="Enshroud logo"/></Navbar.Brand>
						<Nav.Link eventKey="home" title="Home Page" className="p-2 m-2">Home</Nav.Link>
						<Nav.Link eventKey="depositAssets" title="Deposit assets to Enshroud smart contract" className="p-2 m-2">Deposit</Nav.Link>
						<Nav.Link eventKey="mintENFTs" title="Mint eNFTs from deposited assets" className="p-2 m-2">Mint</Nav.Link>
						<Nav.Link eventKey="spendENFTs" title="Spend eNFTs" className="p-2 m-2">Spend</Nav.Link>
						<Nav.Link eventKey="burnENFTs" title="Redeem eNFTs for backing assets" className="p-2 m-2">Burn</Nav.Link>
						<Nav.Link eventKey="receipts" title="Go to Transaction History" className="p-2 m-2">History</Nav.Link>

						{ /* staking navbar */ }
						<NavDropdown title="Staking" id="stakingDropdown"
							className="p-2" menuVariant="dark"
						>
							<NavDropdown.Item eventKey="daoStaking">DAO Staking</NavDropdown.Item>
							<NavDropdown.Item eventKey="mvoStaking">MVO Staking</NavDropdown.Item>
							<NavDropdown.Item eventKey="crowdSale">Buy $ENSHROUD</NavDropdown.Item>
						</NavDropdown>

						{ /* admin navbar */ }
						<NavDropdown title="Admin"
							id="adminDropdown"
							className="p-2" menuVariant="dark"
						>
							<NavDropdown.Item eventKey="mvoConfig">MVO Config</NavDropdown.Item>
							<NavDropdown.Item eventKey="enshMinting">ENSHROUD Minting</NavDropdown.Item>
							<NavDropdown.Item eventKey="greylistAdmin">Greylisting</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav className="flex-row flex-grow-1 justify-content-end">
						<Nav.Link href="#" title="Connected Network / Account" className="p-2 m-2">{chainConn.chainConfig.chain} / {acct}</Nav.Link>
					</Nav>
				</Container>
			</Navbar>
		</Container>
	);
}

export default EnshNavbar;
