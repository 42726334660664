/*
 * last modified---
 * 	09-13-23 use real page names
 *
 * purpose---
 * 	header for dApp pages, to supply metadata
 */

import React from 'react';

/* show page header
 * @param props.pageName the string identifying the page we're displaying
 */
function Header(props) {
	const description = `Enshroud dApp ${props.pageName} Page`;
	const twDescrip = `${props.pageName} Page for Enshroud dApp`;
	const title = `dApp ${props.pageName} Page`;
	return (
		<header>
			<meta charSet="utf-8"/>
			<meta name="viewport"
				content="width=device-width, initial-scale=1"/>
			<meta name="author" content="Enshroud Developers"/>
			<meta name="description" content={description}/>
			<meta name="twitter:card" content=""/>
			<meta name="twitter:site" content="@enshroud"/>
			<meta name="twitter:creator" content="@enshroud"/>
			<meta name="twitter:title" content={title}/>
			<meta name="twitter:description" content={twDescrip}/>
			<meta name="twitter:image" content="images/twitterImage.png"/>
			<title>{title}</title>
			<link rel="icon" href="images/favicon.ico"/>
		</header>
	);
}

export default Header;
