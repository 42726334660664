/*
 * last modified---
 * 	07-14-23 add mintEnabled and totalSupply
 * 	07-10-23 new
 *
 * purpose---
 * 	provide admin UI to mint Enshroud erc20 tokens
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Enshroud.css';
import { useState } from 'react';
import useEth from '../EthContext/useEth';
import NoticeWrongNetwork, { NoticeNoArtifact } from '../Notices.jsx';
import DoEnshroudMints from './DoEnshroudMints.jsx';


/* framework for holding state and displaying minting-related UI
 * @param props dummy variable (cue to React)
 */
function EnshroudMinting(props) {
	const { state } = useEth();
	const [enshMintings, setEnshMintings] = useState([]);
	const [mintEnabled, setMintEnabled] = useState(false);
	const [totalSupply, setTotalSupply] = useState(0);

	// method to add an array of mintages by replacing state
	function handleAddMintings(mintings) {
		setEnshMintings([...mintings]);
	}

	// method to add a single new mintage record by replacing state
	function handleAddMinting(minting) {
		setEnshMintings([...enshMintings, minting]);
	}
	
	// method to set whether the current user account is a valid minter
	function handleSetMinter(authorized) {
		setMintEnabled(authorized);
	}

	// method to update total supply after minting
	function handleUpdateTotalSupply(total) {
		if (total >= totalSupply) {
			setTotalSupply(total);
		} else {
			console.error("New total ENSHROUD supply (" + total
						+ ") smaller than previous (" + totalSupply + ")");
		}
	}

	// build output
	const mintConfig =
		<>
			<div className="container">
				<DoEnshroudMints mintings={enshMintings}
					addMintRecord={handleAddMinting}
					addMintRecords={handleAddMintings}
					setMintAuth={handleSetMinter}
					isMintAuth={mintEnabled}
					totalSupply={totalSupply}
					updateTotal={handleUpdateTotalSupply}
			/>
			</div>
		</>;

	// render output as appropriate
	return (
		<div id="MintConf">
		{
			!state.artifacts.EnshroudToken ? <NoticeNoArtifact /> :
			state.contracts == null
				|| !state.contracts["EnshroudToken"] ? <NoticeWrongNetwork /> :
				mintConfig
		}
		</div>
	);
}

export default EnshroudMinting;
