/*
 * last modified---
 * 	10-06-23 new
 *
 * purpose---
 * 	provide a dismissable Alert with styling, as an alternative to alert()
 */

import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

/* dismissable Alert open by default with dismissible X control
 * @param props.variant type of alert (danger by default)
 * @param props.title any header text to display for the alert (optional)
 * @param props.data any valid HTML to be displayed in the alert
 */
function DAlert(props) {
	const [show, setShow] = useState(true);
	const variant = props.variant !== undefined ? props.variant : "danger";

	return (
		<Alert variant={variant} show={show}
			onClose={() => setShow(false)} dismissible
		>
			<Alert.Heading>{props.title}</Alert.Heading>
			<div className="p-2">
				{props.data}
			</div>
		</Alert>
	);
}

export default DAlert;
