/*
 * last modified---
 * 	07-13-24 new
 *
 * purpose---
 * 	provide stakings UI for Enshroud erc20 tokens crowdsale
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Enshroud.css';
import { useState } from 'react';
import useEth from '../EthContext/useEth';
import NoticeWrongNetwork, { NoticeNoArtifact } from '../Notices.jsx';
import EnshroudPurchases from './EnshroudPurchases.jsx';


/* framework for holding state and displaying crowdsale-related UI
 * @param props dummy variable (cue to React)
 */
function EnshroudCrowdsale(props) {
	const { state } = useEth();
	const [currentTier, setCurrentTier] = useState(0);
	const [tierTokens, setTierTokens] = useState(0);
	const [tierPrice, setTierPrice] = useState(0);
	const [userTokens, setUserTokens] = useState(0);

	// method to set the current Tier
	function handleSetTier(tier) {
		if (tier >= 0) {
			setCurrentTier(tier);
		}
	}

	// method to set the tokens remaining in the current Tier
	function handleSetTierTokens(tokens) {
		setTierTokens(tokens);
	}

	// method to set the price in ETH for the current Tier
	function handleSetTierPrice(price) {
		if (price > 0) {
			setTierPrice(price);
		}
	}

	// method to set user balance of $ENSHROUD tokens
	function handleSetBalance(newBal) {
		if (newBal >= 0) {
			setUserTokens(newBal);
		}
	}

	// build output
	const saleConfig =
		<>
			<div className="container">
				<EnshroudPurchases
					tierNumber={currentTier}
					tierTokens={tierTokens}
					tierPrice={tierPrice}
					userBalance={userTokens}
					setTier={handleSetTier}
					setTokens={handleSetTierTokens}
					setPrice={handleSetTierPrice}
					setUserBal={handleSetBalance}
			/>
			</div>
		</>;

	// render output as appropriate
	return (
		<div id="CrowdsaleConf">
		{
			!state.artifacts.EnshroudToken ? <NoticeNoArtifact /> :
			state.contracts == null
				|| !state.contracts["EnshroudToken"] ? <NoticeWrongNetwork /> :
				saleConfig
		}
		</div>
	);
}

export default EnshroudCrowdsale;
