/*
 * last modified---
 * 	12-27-23 new
 *
 * purpose---
 * 	provide admin UI to handle viewing greylisted IDs and ungreylisting fuction
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Enshroud.css';
import { useState } from 'react';
import useEth from '../EthContext/useEth';
import NoticeWrongNetwork, { NoticeNoArtifact } from '../Notices.jsx';
import DoEnshroudGreylisting from './DoEnshroudGreylisting.jsx';


/* framework for holding state and displaying greylisting-related UI
 * @param props dummy variable (cue to React)
 */
function EnshroudGreylisting(props) {
	const { state } = useEth();
	const [greylistings, setGreylistings] = useState([]);
	const [greylistEnabled, setGreylistEnabled] = useState(false);

	// method to add to an array of greylistings by replacing state
	function handleAddGreylistings(greylistRecs) {
		setGreylistings([...greylistRecs]);
	}

	// method to record a single new ungreylisting event by replacing state
	function handleGreylistRemoval(greylistRec) {
		if (greylistRec === undefined || greylistRec === null) {
			console.error("handleGreylistRemoval(): missing input");
			return;
		}
		setGreylistings(greylistings.map(g => {
			if (g.id === greylistRec.id) {
				// this one will have .ungreylistBlock field set
				return greylistRec;
			} else {
				return g;
			}
		}));
	}

	// method to set whether the current user account is a valid admin
	function handleGreylistAdmin(isAdmin) {
		setGreylistEnabled(isAdmin);
	}

	// build output
	const greylistConfig =
		<>
			<div className="container">
				<DoEnshroudGreylisting greylistings={greylistings}
					addGreylistRecs={handleAddGreylistings}
					recordUnGreylist={handleGreylistRemoval}
					isGreylistEnabled={greylistEnabled}
					setGreylistAdmin={handleGreylistAdmin}
				/>
			</div>
		</>;
	
	// render output as appropriate
	return (
		<div id="GreylistConf">
		{
			!state.artifacts.EnshroudProtocol ? <NoticeNoArtifact /> :
			state.contracts == null
				|| !state.contracts["EnshroudProtocol"] ? <NoticeWrongNetwork />
				: greylistConfig
		}
		</div>
	);
}

export default EnshroudGreylisting;
