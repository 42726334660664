/*
 * last modified---
 * 	07-26-24 wording updates
 * 	02-03-24 add section regarding ECIES usage
 * 	01-29-24 new
 *
 * purpose---
 * 	provide a single page of explication on the nature and use of Enshroud
 */

import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';


/* render an explanatory page about Enshroud
 * @param props.onSelect method to shift to other pages
 */
function Explainer(props) {
	// render the page
	return (
		<div className="Explainer">
		<Container fluid align="center">
			<Card>
				<Card.Title>What Is Enshroud?</Card.Title>
				<ListGroup className="list-group-flush" align="left">
					<ListGroup.Item>
						Enshroud is a technology for sending encrypted payments
						on EVM-compatible blockchains.  Value is represented
						by encrypted non-fungible tokens (<i>eNFTs</i>), where
						the details of the NFT's metadata are "enshrouded"
						using AES-256 encryption.<br/><br/>
						Just as encrypting an email conceals the contents from
						everyone but the recipients, only the individual
						payees to whom you send eNFTs can access their contents.
						Regular token spends made in the open are like
						unencrypted emails.
					</ListGroup.Item>
					<ListGroup.Item>
						In order that eNFTs can be trusted, they are generated
						and signed by a consensus multisig of Layer2 nodes
						called <i>MVOs</i> (for Metadata Validator Oracles).
						These MVO nodes validate user transactions, sign and
						encrypt output eNFTs, and generate and sign blinded
						hashes of eNFT details, which are stored and validated
						by the smart contract.<br/><br/>
						The MVO nodes <b>never</b> store
						or transmit any funds.  They are merely crypto
						engines running published-source software to perform
						encryption operations for users who make signed requests
						in regard to their own private data.
						Users review and submit each blinded transaction
						directly to the blockchain after the MVO layer has
						prepared it.
					</ListGroup.Item>
				</ListGroup>
			</Card>
			<br/><br/>

			<Card>
				<Card.Title>How Is Enshroud Used?</Card.Title>
				<ListGroup className="list-group-flush" align="left">
					<ListGroup.Item>
						The general procedure is as follows:
						<ListGroup as="ol" numbered>
							<ListGroup.Item as="li">
								Deposit tokens into the Enshroud smart contract
								to establish a balance for your account address.
								Any fungible ERC-20, ERC-777, or ERC-4626 token
								is supported.
							</ListGroup.Item>
							<ListGroup.Item as="li">
								Mint one or more eNFTs (to yourself or others)
								against your token balance.
							</ListGroup.Item>
							<ListGroup.Item as="li">
								Spend eNFTs to other accounts.  You can spend
								multiple different assets simultaneously, to
								multiple payee addresses, up to a max of 20
								total eNFTs minted in a single transaction.
							</ListGroup.Item>
							<ListGroup.Item as="li">
								To withdraw tokens from the Enshroud smart
								contract, burn one or more eNFTs that you own.
							</ListGroup.Item>
							<ListGroup.Item as="li">
								Encrypted receipts (transaction history items)
								are generated by MVOs for all operations, which
								can be be accessed only by the payers and
								payees.  You can download or permanently delete
								your receipt items, along with their decryption
								keys.
								(Receipts are not stored on the blockchain.)
							</ListGroup.Item>
						</ListGroup>
						See the corresponding Deposit, Mint, Spend, Burn and
						History items in the top menu bar above.
					</ListGroup.Item>

					<ListGroup.Item>
						Notice that eNFTs work like UXTOs on Bitcoin: inputs are
						always burned in every operation, and you will receive
						a fresh eNFT for any change amount.  Unlike ordinary
						NFTs, an Enshroud eNFT is not directly transferrable.
					</ListGroup.Item>

					<ListGroup.Item>
						In using Enshroud, you will follow two general steps
						for all Mint/Spend/Burn operations.  First, you will
						interact with our "Layer 2" MVO nodes.  Your inputs
						will be signed by you, using EIP-712 signatures.  This
						step allows you to review all the data in fine detail
						before signing and submitting it to the selected MVO.
						Second, you will again review what the MVO has returned
						to you, and only then submit it to the smart contract
						on the blockchain as a separately signed step.
						<br/><br/>
						You will also interact with the MVO layer (only) to
						download your current list of valid eNFTs, or
						transaction history receipts.
					</ListGroup.Item>
					<ListGroup.Item variant="warning">
						N.B.: Communications with our MVOs (Layer2) are secured
						using <a target="_blank" rel="noreferrer noopener" href="https://github.com/ecies/js">ECIES</a>,
						a hybrid ECDSA/AES-256 encryption algorithm,
						which provides end-to-end encryption between
						your browser and the MVO.  It is therefore quite safe
						to transmit Layer2 requests over plain HTTP, i.e.
						without a <i>https://</i> &nbsp;&nbsp;URL.
						<br/><br/>
						<b>Your browser might give you grief about this</b> if
						you access this dApp using a plain <i>http://</i> &nbsp;
						URL and have set a global config option to disallow
						"insecure" connections. (On some browsers this is now
						the default value of this option.)  The connection
						itself is unencrypted, but only encrypted data is sent
						across it.  (ECIES is actually stronger encryption than
						the SSL/TLS utilized by HTTPS.)
						Therefore, <b>you may need to define an exception or
						override the default to access this dApp insecurely.</b>
						<br/><br/>
						Note that this <b>won't</b> be required if you are
						accessing this dApp via IPFS
						using <a target="_blank" rel="noreferrer noopener" href="https://dist.ipfs.tech/#kubo">Kubo</a>,
						as recommended.  In that case the URL the browser sees
						is <code>@localhost</code>,
						which is always permitted.  Note too that IPFS
						implicitly guarantees file download integrity, since it
						utilizes hash-based addressing.  (A Web2 server can't
						do this; you are trusting the content it delivers.)
						<br/><br/>
						A separate but related issue is that while one could
						load this dApp via an <i>https://</i> &nbsp; URL, if you
						do so you won't be able to access our MVO URLs (which
						are deemed "insecure") from within the secure context.
						This is why our dApp cannot be accessed using the
						"IPFS Companion" plugin, or any other method that relies
						on secure URLs connected to back-end IPFS gateways.
						Solution: don't do this.  Use IPFS/Kubo (preferred), or
						use a non-secure dApp URL (such as http://enshroud.info)
						with insecure URLs enabled in your browser.  (The latter
						option is inferior for several reasons.)
						<br/><br/>
						If you are curious why we made this design choice, it's
						because the PKI (Public Key Infrastructure) for HTTPS
						is extremely likely to be compromised (and may already
						be compromised).  This could easily lead to widespread
						web traffic interception via government-instigated MITM
						attacks.
						<br/>
						<a target="_blank" rel="noreferrer noopener" href="https://brownstone.org/articles/the-eu-wants-to-spy-on-europeans-internet-use/">
							Please read this for more information.
						</a><br/>
						(And now you understand why browsers are
						trying to force everyone to use HTTPS only, and site
						certificates are available for free.)
						We're simply being careful and planning ahead.
						Plus, we want to make crypto cypherpunk again.
					</ListGroup.Item>

					<ListGroup.Item variant="info">
						Note that Enshroud does not make it impossible for
						observers to see the addresses to which you have minted
						eNFTs.  It merely conceals <b>what</b> you spent,
						because an eNFT can only be decrypted by its owner.
						<br/><br/>
						Enshroud is not a mixer.  It is an anti-surveillance
						tool.
					</ListGroup.Item>
				</ListGroup>
			</Card>
			<br/><br/>

			<Card>
				<Card.Title>For More Information</Card.Title>
				<Card.Body>
					<Card.Text>
						Please see the following documents for additional
						details about how Enshroud works and how it's useful:
					</Card.Text>
					<Card.Link href="downloads/What-is-Enshroud.pdf"
						target="_blank"
						title="An explanation of what Enshroud does">
						What-is-Enshroud.pdf
					</Card.Link>
					<br/>
					<Card.Link
						href="downloads/How-Enshroud-Compares-With-TC.pdf"
						target="_blank"
						title="An comparison between Enshroud and Tornado Cash">
						How-Enshroud-Compares-With-TC.pdf
					</Card.Link>
					<br/>
					<Card.Link
						href="downloads/How-Enshroud-Compares-With-Aztec.pdf"
						target="_blank"
						title="An comparison between Enshroud and Aztec">
						How-Enshroud-Compares-With-Aztec.pdf
					</Card.Link>
					<br/>
					<Card.Link
						href="downloads/Enshroud-Whitepaper.pdf"
						target="_blank"
						title="The complete Enshroud white paper">
						Enshroud-Whitepaper.pdf
					</Card.Link>
					<br/>
				</Card.Body>
			</Card>
			<br/>
		</Container>
		</div>
	);
}

export default Explainer;
