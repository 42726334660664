/*
 * last modified---
 * 	02-26-24 add Sepolia section
 * 	01-05-24 update protocolGenesis to block 355; Ganache now at 192.168.1.7
 * 	12-07-23 new
 *
 * purpose---
 * 	provide definitions for all supported blockchain
 */

// ******** begin definitions for all supported chains
const chainConfigs = [
	{	// local Ganache node
		chainId: 1337,
		chain: "Ganache",
		// this URL is a suggestion, the actual URL will come from givenProvider
		jsonrpcURL: 'ws://192.168.1.7:8545',
		scanURL: '',	// (not natively supported)
		protocolGenesis: 355,
		tokenGenesis: 197,
		assetList:
			[{ symbol: "ETH",
				   contractAddress:"0x0000000000000000000000000000000000000000",
				   balanceOf: 0n,
				   method: 'native',
				   wrapsTo: "0x9745800493fC45ad2Dd122Dc267FB5eAd3e3D1f2",
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "WETH",
				   contractAddress:"0x9745800493fC45ad2Dd122Dc267FB5eAd3e3D1f2",
				   balanceOf: 0n,
				   method: 'tokens',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "ENSHROUD",
				   contractAddress:"0xE1c08596DEb9465184820f2815182157db42757B",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "DAI",
					// TBD wrong address
				   contractAddress:"0x6B175474E89094C44Da98b954EedeAC495271d0F",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 }
			],
		defaultDepFee: 0.3,
		defaultWithFee: 0.3,
	},

	{	// Sepolia testnet
		chainId: 11155111,
		chain: "Sepolia",
		// this URL is a suggestion, the actual URL will come from givenProvider
		jsonrpcURL: 'https://rpc2.sepolia.org',
		scanURL: 'https://sepolia.etherscan.io',
		protocolGenesis: 5385762,
		tokenGenesis: 5385743,
		assetList:
			[{ symbol: "SepoliaETH",
				   contractAddress:"0x0000000000000000000000000000000000000000",
				   balanceOf: 0n,
				   method: 'native',
				   wrapsTo: "0xeACAb661cd78445890BF5f3F3BCE5F5Ea1D76748",
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "WETH",
				   contractAddress:"0xeACAb661cd78445890BF5f3F3BCE5F5Ea1D76748",
				   balanceOf: 0n,
				   method: 'tokens',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "ENSHROUD",
				   contractAddress:"0xd20232b4aAaC77f1b6249887dc9749C798a13E56",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 }
			/*
				   withdrawFee: 0.3 },
				 { symbol: "DAI",
					// NB: mainnet address, doesn't really exist on Sepolia
				   contractAddress:"0x6B175474E89094C44Da98b954EedeAC495271d0F",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 }
			 */
			],
		defaultDepFee: 0.3,
		defaultWithFee: 0.3,
	},

	// TBD: add analogous config for any other selected testnets

	{	// Eth main (placeholder)
		chainId: 1,
		chain: "Ethereum",
		// this URL is a suggestion, the actual URL will come from givenProvider
		jsonrpcURL: 'https://eth.llamarpc.com',
		scanURL: 'https://etherscan.io',
		protocolGenesis: 0,		// TBD not yet deployed
		tokenGenesis: 0,		// TBD not yet deployed
		assetList:
			[{ symbol: "ETH",
				   contractAddress:"0x0000000000000000000000000000000000000000",
				   balanceOf: 0n,
				   method: 'native',
				   wrapsTo: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "ENSHROUD",
					// TBD not yet deployed
				   contractAddress:"0x0000000000000000000000000000000000000000",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "WETH",
				   contractAddress:"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
				   balanceOf: 0n,
				   method: 'tokens',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "WBTC",
				   contractAddress:"0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
				   balanceOf: 0n,
				   method: 'tokens',
				   depositFee: 0.3,
				   withdrawFee: 0.3 },
				 { symbol: "DAI",
				   contractAddress:"0x6B175474E89094C44Da98b954EedeAC495271d0F",
				   balanceOf: 0n,
				   method: 'permit',
				   depositFee: 0.3,
				   withdrawFee: 0.3 }
			],
		defaultDepFee: 0.3,
		defaultWithFee: 0.3,
	}
];
// ******** end definitions for all supported chains

export default chainConfigs;
